import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCYXOdn6bOItztQ47mbn_PQkBLKfm5TyZQ",
  authDomain: "tweetmerch-e12fe.firebaseapp.com",
  projectId: "tweetmerch-e12fe",
  storageBucket: "tweetmerch-e12fe.appspot.com",
  messagingSenderId: "297848462500",
  appId: "1:297848462500:web:400912a29f68fc5f9b9a31",
  measurementId: "G-68JND6G5PR"
};

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);


import React, { useEffect, useState } from 'react';
import Loader from './Loader';
import { db, functions } from './Firebase';
import { doc, getDoc, increment, setDoc } from 'firebase/firestore/lite';
import Slick from 'react-slick';
import InnerImageZoom from 'react-inner-image-zoom';
import { useMediaQuery } from 'react-responsive'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { loadStripe, } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import AppBar from './AppBar';
import { incrementProductStats } from './DatabaseService';

// /products/HykCrRJwMzBWjWrwTCPS
export default function ProductPage(props) {
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const id = props.match.params.id;
    const isDesktop = useMediaQuery({ query: '(min-width: 900px)' });


    useEffect(
        async () => {
            const ds = await getDoc(doc(db, `products/${id}`));
            incrementProductStats(id, 'A_productPageViews');
            setProduct(ds.data());
        }, []);

    async function redirectToCheckout() {
        console.log(product.isLive);
        setIsLoading(true);

        incrementProductStats(id, 'B_purchaseButtonClicks');

        const stripeCreateSession = httpsCallable(functions, 'stripeCreateSession');

        const stripePublicKey = product.isLive ? 'pk_live_51K2PnrBH7kXb8hVr5vngADiJGXWdmGqMCC5pTgZ3HDpOYHW4dixfA6G213nyagDEFsYzXlwENigxA2mJWPgY5pKN003ewyl3gq' : 'pk_test_51K2PnrBH7kXb8hVrptmE4cmupBjrc9HyhEYov0kTOBvFFSglnsDJbVdvqrh8b3YBFNq3tKtzkYpCMBnKBrxE50nI009idnX09t';
        const promiseAllResponse = await Promise.all([
            stripeCreateSession({ productId: id, isLive: product.isLive }),
            loadStripe(stripePublicKey),
        ]);

        const sessionId = promiseAllResponse[0].data;
        const stripe = promiseAllResponse[1];

        stripe.redirectToCheckout({
            sessionId: sessionId
        })
    }


    if (!product || isLoading) {
        return <Loader />
    }

    if (isDesktop) {
        return (
            <div
                style={{
                    maxWidth: 1100,
                    margin: 'auto',
                }}
            >
                <AppBar />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            flex: 1.2,
                            overflow: 'hidden',
                            height: 'calc(70vh + 100px)'
                        }}
                    >
                        <Carousel product={product} height={'70vh'} />
                    </div>
                    <div
                        style={{
                            flex: 1,
                            paddingTop: 12,
                        }}
                    >
                        <Details product={product} redirectToCheckout={redirectToCheckout} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            style={{
                maxWidth: 600,
                margin: 'auto',
            }}
        >
            <AppBar />
            <div style={{
                paddingBottom: 30
            }}>
                <Carousel product={product} height={370} />
            </div>
            <Details product={product} redirectToCheckout={redirectToCheckout} />
        </div>
    );


}

function Carousel({ product, height }) {
    const settings = {
        dots: true,
        arrows: false,
    };
    return (
        // <div
        //     style={{
        //         width: '100%',
        //         paddingBottom: 30,
        //     }}
        // >

        <Slick {...settings}        >
            {
                product.mockupImageUrls.map((mockupImageUrl) => {
                    return <div>
                        <img src={mockupImageUrl} style={{
                            width: 'calc(100% - 34px)',
                            height: height,
                            objectFit: 'cover',
                            margin: 'auto',
                            borderRadius: 6,
                            border: '1px solid #DDDDDD'
                        }} />
                    </div>
                })
            }
        </Slick>

    );
}

function Details({ product, redirectToCheckout }) {
    return (
        <div
            style={{
                margin: '0px 16px',
                marginBottom: 60,
                // maxWidth:450,

            }}
        >
            <div
                style={{
                    fontSize: 25,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: product.isLive ? 'black' : 'red'
                }}
            >
                {product.isLive ? '' : 'TEST PRODUCT | '}Tweet Mug 11oz | @{product.tweet.user.screen_name}
            </div>
            <div
                style={{
                    fontSize: 16,
                    fontWeight: 400,
                    paddingTop: 20,
                    letterSpacing: 0.5,
                    lineHeight: 1.4,

                }}
            >
                $19.99 USD (Free shipping)
                <br />
                Delivery time: 3-7 business days
            </div>

            <div
                onClick={redirectToCheckout}
                style={{
                    cursor: 'pointer',
                    backgroundColor: 'black',
                    color: 'white',
                    fontWeight: 800,
                    fontSize: 15,
                    padding: '16px 30px',
                    marginTop: 20,
                    textAlign: 'center',
                    borderRadius: 6,
                }}
            >
                Buy it now
            </div>

            <div
                style={{
                    fontSize: 14,
                    fontWeight: 300,
                    paddingTop: 32,
                    letterSpacing: 0.5,
                    lineHeight: 1.7,

                }}
            >
                This sturdy mug is perfect for your morning coffee, afternoon tea, or whatever hot beverage you enjoy.
                <br />
                <br />
                Ceramic 11 oz mug dimensions: 3.79″ (9.6 cm) in height, 3.25″ (8.3 cm) in diameter.
                <br />
                <br />
                Dishwasher and microwave safe.
                <br />
            </div>
        </div>
    );
}
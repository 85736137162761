import React, { useEffect, useState } from 'react';
// import Loader from './Loader';
// import { db, functions } from './Firebase';
// import { doc, getDoc, increment, setDoc } from 'firebase/firestore/lite';
import { useMediaQuery } from 'react-responsive'

import AppBar from './AppBar';

export default function HomePage(props) {
    // const [isLoading, setIsLoading] = useState(false);
    const isDesktop = useMediaQuery({ query: '(min-width: 900px)' });


    // useEffect(
    //     async () => {
    //         const ds = await getDoc(doc(db, `products/${id}`));
    //         incrementProductStats(id, 'A_productPageViews');
    //         setProduct(ds.data());
    //     }, []);


    // if (!product || isLoading) {
    //     return <Loader />
    // }

    return (
        <div
            style={{
                maxWidth: 1100,
                margin: 'auto',
            }}
        >
            <AppBar />
            <div
                style={{
                    paddingTop: 100,
                    padding: '20px 18px',
                    fontWeight: 500,
                    fontSize: 18
                }}
            >
            </div>

        </div>
    );

}
import React, { useEffect, useState } from 'react';
import Loader from './Loader';

// /products/HykCrRJwMzBWjWrwTCPS
export default function DefinitionPage(props) {
    const word = props.match.params.word;
    const [definitionEntry, setDefinitionEntry] = useState();

    useEffect(
        async () => {
            const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
            const json = await response.json();
            //TODO DEBUG
            // const json = [{ "word": "dog", "phonetic": "dɒɡ", "phonetics": [{ "text": "dɒɡ", "audio": "//ssl.gstatic.com/dictionary/static/sounds/20200429/dog--_gb_2.mp3" }], "origin": "Old English docga, of unknown origin.", "meanings": [{ "partOfSpeech": "noun", "definitions": [{ "definition": "a domesticated carnivorous mammal that typically has a long snout, an acute sense of smell, non-retractable claws, and a barking, howling, or whining voice.", "synonyms": ["canine", "hound", "mongrel", "cur", "tyke", "bitch", "pup", "puppy", "whelp", "doggy", "pooch", "mutt", "pupper", "doggo", "man's best friend", "Rover", "Fido", "mong", "bitzer"], "antonyms": [] }, { "definition": "an unpleasant, contemptible, or wicked man.", "example": "he was interrupted by cries of ‘dirty dog!’", "synonyms": [], "antonyms": [] }, { "definition": "used in names of dogfishes, e.g. sandy dog, spur-dog.", "synonyms": [], "antonyms": [] }, { "definition": "a mechanical device for gripping.", "synonyms": [], "antonyms": [] }, { "definition": "feet.", "synonyms": [], "antonyms": [] }, { "definition": "barriers used to keep horses off a particular part of the track.", "synonyms": [], "antonyms": [] }] }, { "partOfSpeech": "verb", "definitions": [{ "definition": "follow (someone) closely and persistently.", "example": "photographers seemed to dog her every step", "synonyms": ["pursue", "follow", "stalk", "track", "trail", "shadow", "hound", "plague", "beset", "bedevil", "assail", "beleaguer", "blight", "trouble", "torment", "haunt", "tail"], "antonyms": [] }, { "definition": "act lazily; fail to try one's hardest.", "example": "Eric had a reputation for dogging it a little", "synonyms": [], "antonyms": [] }, { "definition": "grip (something) with a mechanical device.", "example": "she has dogged the door shut", "synonyms": [], "antonyms": [] }] }] }];
            setDefinitionEntry(json[0]);
        }, []);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    if (!definitionEntry) {
        return <Loader />
    }

    return (
        <div style={{
            padding: 20,
            fontFamily: 'times',
            letterSpacing: 1,
        }}>
            <div style={{
                fontSize: 64,
                fontWeight: 900,

            }}>
                {
                    definitionEntry.word //TODO CLIPS WITH LONG WORDS LIKE antidisestablishmentarianism
                }
            </div>
            {/* <div style={{
                backgroundColor: 'black',
                height: 2, 
                marginTop: 8,
            }} /> */}
            <div style={{
                marginTop: 2,
                fontSize: 18,
                fontWeight: 400,
            }}>
                [{definitionEntry.phonetic}]
            </div>
            <div style={{
                marginTop: 20,
                fontSize: 23,

                lineHeight: 1.5,
                fontWeight: 400,
            }}>
                {definitionEntry.meanings[0].definitions[0].definition}
            </div>
        </div>
    );
}
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import HomePage from './HomePage';
import ProductPage from './ProductPage';
import CompletePage from './CompletePage';
import DefinitionPage from './DefinitionPage';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path='/' exact component={HomePage} />
          <Route path='/:id' exact component={ProductPage} />
          <Route path='/complete/:id' exact component={CompletePage} />
          <Route path='/definitionpage/:word' exact component={DefinitionPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

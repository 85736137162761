import React, { useEffect, useState } from 'react';
import Loader from './Loader';
import { db } from './Firebase';
import { doc, getDoc } from 'firebase/firestore/lite';
import AppBar from './AppBar';
import { incrementProductStats } from './DatabaseService';



// /products/HykCrRJwMzBWjWrwTCPS
export default function CompletePage(props) {
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const id = props.match.params.id;

    useEffect(
        async () => {
            const ds = await getDoc(doc(db, `products/${id}`));
            incrementProductStats(id, 'C_completePageViews');
            setProduct(ds.data());
        }, []);

    if (!product || isLoading) {
        return <Loader />
    }

    return (
        <div
            style={{
                maxWidth: 600,
                margin: 'auto',
            }}
        >
            <AppBar />
            <div
                style={{
                    padding: '18px 18px',

                }}
            >
                <div style={{
                    fontSize: 17,
                    fontWeight: 700,
                    color: '#1B7604',

                }}>
                    Thank you, your order has been placed.
                </div>
                <img src={product.mockupImageUrls[0]}

                    style={{
                        marginTop: 24,
                        width: '100%',
                        height: 400,
                        borderRadius: 10,
                        objectFit: 'cover',
                        border: '1px solid #DDDDDD'
                    }}
                />
            </div>
        </div>
    );

}
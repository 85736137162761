import MoonLoader from 'react-spinners/MoonLoader';

function Loader () {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
    }} >
        <MoonLoader color='black' size={35} />
    </div>
}

export default Loader;